.yacht-page {
  width: 100%;
  background: url("../../images/ornamen-voyage.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.yacht-page img {
  width: 100%;
}

.contents-container {
  width: 68.75%;
  margin: 0 auto;
}

.contents-title {
  margin-top: 2rem;
  color: var(--color-yellow);
  text-align: center;
  font-family: var(--font-rotis);
}

.contents-title h3 {
  font-size: 2.375rem;
  font-weight: 400;
  line-height: 2.85rem;
}

.contents-title p {
  font-size: 0.938rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.grid-images {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 32.3% 32.3% 32.3%;
  grid-template-rows: auto;
  grid-template-areas:
    "item1 item1 item2"
    "item1 item1 item3";
  gap: 0.7rem;
  overflow: hidden;
}

.item1 {
  grid-area: item1;
}

.item1 img {
  width: 100%;
  height: 99%;
}

.item2 {
  grid-area: item2;
}

.item3 {
  grid-area: item3;
}

.wrapper-specification {
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 3rem 0;
}

.total-cabin {
  width: 35%;
}
.level {
  width: 60%;
}

.title-total-cabin,
.title-level {
  font-size: 1.3rem;
}

.desc-total-cabin span,
.desc-level span {
  display: block;
  width: 100%;
  height: 3rem;
  border-bottom: 0.1rem solid var(--color-yellow);
  padding: 1rem 0;
}

@media only screen and (max-width: 768px) {
  .yacht-page {
    margin-top: 5rem;
  }

  .desc-total-cabin span,
  .desc-level span {
    height: auto;
  }
}
