.enquire {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-enquire {
  width: 50%;
  display: flex;
  animation: fadeIn 1s;
}

.img-enquire,
.content-enquire {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.img-enquire img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-enquire {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
}

.content-enquire {
  padding: 1.3rem 1rem;
  background-color: white;
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  line-height: 1.3rem;
  font-size: 0.8rem;
}

.desc-content-enquire h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.desc-content-enquire form {
  margin-top: 1rem;
}

.group-input {
  margin-bottom: 0.6rem;
}

.group-input label {
  display: block;
  font-style: italic;
}

.group-input input,
select {
  display: block;
  width: 100%;
  height: 2rem;
  padding: 0.1rem 0.3rem;
  font-size: 0.9rem;
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  text-transform: capitalize;
  outline: none;
  border: 0.1rem solid var(--color-yellow);
}

.span-enquire {
  display: block;
}

.submit-enquire {
  height: 2rem;
  width: 10rem;
  border: none;
  background-color: var(--color-yellow);
  color: white;
  text-transform: capitalize;
  font-family: var(--font-rotis);
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .container-enquire {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
}
