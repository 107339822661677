:root {
  --color-white: rgba(255, 255, 255, 1);
  --color-yellow: rgba(154, 128, 35, 1);
  --font-italiana: "Italiana";
  --font-roboto: "Roboto";
  --font-crimson: "Crimson Pro";
  --font-rotis: "rotis";
  --font-trajan: "trajan";
  --font-gotham: "gotham";
  /* --font-laBelle: "laBelle"; */
  --width-container-mobile: 92%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

a.active {
  text-decoration: underline;
}

@media only screen and (min-width: 1366px) {
  html {
    font-size: 16px;
  }
}
