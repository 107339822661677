.footer {
  width: 100%;
  /* height: 7.438rem; */
  overflow: hidden;
  padding: 2.5rem 3.125rem 2.438rem;
  background-color: #efefef;
  border-top: 0.813rem solid var(--color-yellow);
  /* display: flex;
  justify-content: space-between;
  font-family: var(--font-rotis);
  color: var(--color-yellow); */
}

.footer-wrapper {
  /* height: 7.438rem; */
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-rotis);
  color: var(--color-yellow);
  flex-wrap: wrap;
}

.kontak-wrapper {
  display: flex;
  gap: 0.7rem;
  align-items: center;
}

.footer-image:hover {
  cursor: pointer;
}
.kontak-wrapper img:hover {
  cursor: pointer;
}

.footer-kontak,
.footer-nav ul,
.footer-subcribe {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 7.438rem;
  margin-bottom: 2rem;
  font-size: 1.125rem;
}

/* Press Blog dihide untuk nimpa di atas*/
.footer-nav ul {
  height: 4rem;
}

.subcribe-form {
  height: 1.625rem;
  width: 17.5rem;
  overflow: hidden;
  background-color: var(--color-yellow);
  border-radius: 0.5rem;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
}

.subcribe-title {
  font-family: var(--font-gotham);
  font-size: 0.938rem;
}

.subcribe-form input {
  height: 100%;
  width: 12.5rem;
  background: #d7d7d7;
  border: none;
  padding: 0.3rem 0.5rem;
  outline: none;
  color: var(--color-yellow);
  font-family: var(--font-gotham);
}

.subcribe-form input::placeholder {
  color: var(--color-yellow);
  font-style: italic;
  font-family: var(--font-gotham);
}

.subcribe-form button {
  height: 100%;
  width: 5rem;
  border: none;
  background: none;
  color: white;
  font-size: 0.938rem;
  font-family: var(--font-gotham);
}

.subcribe-form button:hover {
  cursor: pointer;
  background-color: rgb(175, 157, 0);
}

.subcribe-images {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.subcribe-images a img {
  transition: 0.2s ease;
}

.subcribe-images a img:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 1.25rem 1.5rem;
  }

  .footer-wrapper {
    width: var(--width-container-mobile);
  }

  .footer-nav ul {
    margin-bottom: 1rem;
  }

  .footer-nav ul li {
    text-align: left;
  }

  .footer-image {
    /* margin: 0 auto; */
    margin-bottom: 0.5rem;
    margin-left: -1rem;
    width: 9rem;
  }

  .footer-image img {
    width: 100%;
    object-fit: cover;
  }

  .footer-kontak,
  .footer-subcribe {
    height: 5.5rem;
    margin-bottom: 1.8rem;
  }
}
