.gallery {
  width: 100%;
  padding: 1rem 0;
}

.gallery-title {
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  text-align: center;
  margin-bottom: 1rem;
}

.gallery-title h3 {
  font-size: 2.375rem;
  font-weight: 400;
}

.gallery-title p {
  font-size: 1.063rem;
  font-weight: 400;
}

.gallery-image {
  margin-bottom: 1.875rem;
  width: 100%;
  height: 33.688rem;
  position: relative;
  overflow: hidden;
}

.gallery-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.wrapper-video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: video 1s ease;
}

/* .wrapper-video .close-btn {
  font-size: 1.8rem;
  font-family: var(--font-rotis);
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 10%;
  right: 20%;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: black;
  cursor: pointer;
} */

.gallery-image video {
  width: 84vw;
  height: 90vh;
}

.gallery-container {
  /* width: 61.875rem; */
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.313rem;
  margin-bottom: 1.813rem;
}

.img-wrapper {
  width: 14.875rem;
  height: 9.875rem;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease;
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.img-wrapper:hover {
  box-shadow: 0.1rem 0.1rem 0.7rem rgb(39, 39, 39);
}

@keyframes video {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hidden {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media only screen and (max-width: 768px) {
  .gallery-image {
    height: 15rem;
  }

  .gallery-container {
    width: var(--width-container-mobile);
    overflow-x: scroll;
    padding: 0.5rem 0;
    justify-content: flex-start;
    transition: 0.3s ease;
  }

  .gallery-image video {
    width: 90%;
    height: 50%;
  }

  .img-wrapper {
    flex-shrink: 0;
  }
}
