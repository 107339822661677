.navmobile {
  width: 100vw;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: rgba(1, 1, 1, 0.7);
  color: rgb(255, 255, 255);
  margin-top: 5rem;
  animation: down 0.3s ease;
}

.navmobile ul {
  display: flex;
  flex-direction: column;
}

.navmobile ul li:nth-child(4) {
  order: -1;
}

@keyframes down {
  from {
    transform: translateY(-5rem);
    opacity: 0;
  }
  from {
    opacity: translateY(0);
  }
}
