.exclusive-page {
  width: 100%;
  background: url("../../images/ornamen-voyage.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.exclusive-page img {
  width: 100%;
}

.contents-container {
  width: 80%;
  margin: 0 auto;
  padding: 0 0 2rem 0;
}

.contents-title {
  margin-top: 2rem;
  color: var(--color-yellow);
  text-align: center;
  font-family: var(--font-rotis);
}

.contents-title h3 {
  font-size: 2.375rem;
  font-weight: 400;
  line-height: 2.85rem;
}

.contents-title p {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

/* Card Offer*/
.itinerary-wrapper-card {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.card-itinerary {
  /* width: 21.944rem; */
  width: 40%;
  height: 34.889rem;
  /* background-color: tomato; */
  border: 0.01rem solid rgb(170, 170, 170, 0.3);
  border-style: inset;
  /* position: relative; */
  overflow: hidden;
  border-radius: 0.2rem;
  margin-bottom: 2rem;
  transition: 0.2s ease;
}

.card-itinerary:hover {
  border: none;
  border: 0.01rem solid rgb(170, 170, 170, 0.1);
  box-shadow: 0px 14px 20px -5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 14px 20px -5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 14px 20px -5px rgba(0, 0, 0, 0.5);
}

.card-itinerary img {
  /* position: absolute; */
  /* top: -0.1rem; */
  /* left: -0.1rem; */
  height: 17.222rem;
  width: 100.8%;
  margin-left: -0.1rem;
  margin-top: -0.1rem;

  object-fit: cover;
  object-position: center;
}

.contents-itinerary {
  /* width: 100%; */
  padding: 0.8rem 1rem;
  /* border: 1px solid gray; */
  overflow: hidden;
}

.content-firstline {
  display: flex;
  justify-content: space-between;
  height: 5rem;
  overflow: hidden;
}

.days {
  font-family: var(--font-roboto);
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
  width: 18rem;
}

.days span {
  display: block;
}

.from,
.to {
  font-family: var(--font-crimson);
  font-size: 1.2rem;
  text-transform: uppercase;
}

.from-to p {
  color: rgba(106, 106, 106, 1);
  font-size: 0.833rem;
  font-family: var(--font-crimson);
}

.love img {
  width: 0.889rem;
  height: 0.833rem;
  object-fit: cover;
  transition: 0.2s ease;
}

.love img:hover {
  cursor: pointer;
  transform: scale(0.9);
}

/* Secondline */
.content-secondline {
  margin: 0.5rem 0;
  border-bottom: 0.01rem solid gray;
  border-top: 0.01rem solid gray;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-roboto);
  text-transform: uppercase;
}

.depart {
  font-family: var(--font-roboto);
  font-size: 0.7rem;
  color: rgba(106, 106, 106, 1);
  text-transform: uppercase;
}

.duration,
.fares-from {
  border-left: 0.01rem solid rgba(106, 106, 106, 1);
  padding: 0 0.5rem;
}

.tanggal-departs {
  font-size: 0.833rem;
}

.tahun-departs,
.idr {
  font-size: 0.7rem;
}

.mio {
  text-transform: lowercase;
}

/* Thirdline */
.content-thirdline {
  border-top: 0.01rem solid gray;
  /* border-bottom: 0.1rem solid gray; */
  text-transform: uppercase;
  font-size: 0.7rem;
  padding: 0.5rem 0 0.5rem 0;
  line-height: 1rem;
}

.embarking {
  color: var(--color-yellow);
}

/* Fourthline */
.content-fourthline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-size: 0.85rem;
  font-family: var(--font-roboto);
}

.button-view {
  width: 7.5rem;
  height: 1.944rem;
  background-color: var(--color-yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  transition: all 0.5s ease;
  border-radius: 0.2rem;
}

.button-view:hover {
  cursor: pointer;
  background-color: rgb(241, 239, 123);
  color: var(--color-yellow);
}

/* Fiveline */
form.content-fiveline {
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: var(--font-roboto);
}

.content-fiveline input {
  margin-right: 0.3rem;
  cursor: pointer;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.889rem 0;
}

.button-wrapper button {
  width: 7.222rem;
  height: 1.944rem;
  border: none;
  background-color: var(--color-yellow);
  text-transform: uppercase;
  color: var(--color-white);
  font-size: 0.85rem;
  font-family: var(--font-crimson);
}

.button-wrapper button:hover {
  cursor: pointer;
  background-color: rgb(119, 119, 1);
}

@media only screen and (max-width: 768px) {
  .exclusive-page {
    margin-top: 5rem;
  }

  .contents-container {
    width: var(--width-container-mobile);
  }

  .itinerary-wrapper-card {
    flex-direction: column;
  }

  .card-itinerary {
    width: 100%;
    height: auto;
  }

  .content-firstline {
    height: auto;
  }
}
