.hero {
  width: 100%;
  /* height: 100vh; */
  padding: 1.5rem 0;
  position: relative;
}

.background-ornamen {
  position: absolute;
  right: 0;
  bottom: 0;
}

.hero-container {
  /* width: 61.875rem; */
  width: 80%;
  margin: 0 auto;
}

.hero-container img {
  width: 100%;
  object-fit: cover;
}

.contents {
  margin-top: 1.188rem;
  color: var(--color-yellow);
  text-align: center;
  font-family: var(--font-rotis);
}

.contents h3 {
  font-size: 2.375rem;
  font-weight: 400;
  line-height: 2.85rem;
}
.contents p {
  font-size: 0.938rem;
  line-height: 1.5rem;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .hero-container {
    width: var(--width-container-mobile);
  }
}
