.voyage {
  width: 100%;
  /* background: rgba(196, 196, 196, 0.13); */
  background: url("../../images/ornamen-voyage.png");
  background-size: cover;
  padding: 2.813rem 0;
}

.voyage-title {
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  text-align: center;
  margin-bottom: 2.813rem;
}

.voyage-title h3 {
  font-size: 2.375rem;
  font-weight: 400;
}
.voyage-title p {
  font-size: 1.063rem;
  font-weight: 400;
}

.voyage-container {
  /* width: 61.875rem; */
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  /* margin-top: 1.813rem;
  margin-bottom: 1.813rem; */
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.card-voyage {
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-voyage {
  /* width: 10rem;
  height: 10rem; */
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.img-voyage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  transition: all 0.3s ease;
}

.img-voyage img:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.img-title {
  font-size: 1.188rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .voyage-container {
    width: var(--width-container-mobile);
    gap: 2rem;
  }
}
