.voyagepage {
  width: 100%;
}

.voyagepage img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .voyagepage {
    margin-top: 5rem;
  }
}
