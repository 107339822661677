.popupgallery {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupgallery-container {
  width: 80vw;
  height: 80vh;
  display: flex;
  animation: fadeIn 1s;
  position: relative;
  background-color: white;
  overflow: hidden;
}

.close-pop {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(236, 236, 236);
  color: var(--yellow-Color);
  font-weight: 700;
  top: 6vh;
  right: 8vw;
  cursor: pointer;
  z-index: 2;
  font-size: 1.8rem;
  animation: fadeIn 1.5s;
  overflow: hidden;
}

.arrow {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(233, 233, 233);
  color: var(--yellow-Color);
  font-size: 2rem;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  z-index: 999;
}

.cards-slider {
  display: flex;
  transition: all 1s ease-in-out;
}

.card-wrapper {
  width: 100%;
  height: 100%;
}

/* .image-container {
  width: 100%;
  height: 100%;
} */

.image-container img {
  width: 80vw;
  height: 80vh;
  object-fit: cover;
}

/* .popupgallery-wrapper {
    margin: 0 auto;
    margin-top: 3.875rem;
    // width: $widthContainer;
    width: 100%;
    height: 36.875rem;
    overflow: hidden;

    // .arrow {
       position: absolute;
       width: 2rem;
       height: 2rem;
       border-radius: 50%;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: $grayColor;
       color: white;
       top: 0;
       bottom: 0;
       margin: auto;
       cursor: pointer;
       z-index: 2;
     }

    .cards__slider {
      height: 100%;
      // transition: all 0.3s ease-in-out;
      // animation: slider 10s ease-in-out infinite;
      padding: 0 5%;

      @keyframes slider {
        0% {
          transform: translateX(0%);
        }
        20% {
          transform: translateX(0%);
        }
        40% {
          transform: translateX(-10%);
        }
        60% {
          transform: translateX(-25%);
        }
        80% {
          transform: translateX(-55%);
        }
        100% {
          transform: translateX(0%);
        }
      }

      @include desktop {
        display: flex;
        gap: 4rem;
        // align-items:center;
      }

      .card__wrapper {
        width: 18.125rem;
        // height: 36.875rem;
        margin-bottom: 1rem;

        .image__container {
          width: 15.313rem;
          height: 29.563rem;
          // width: 18.125rem;
          // height: 32.5rem;
          position: relative;
          // background-color: blue;

          img {
            // width: 13.188rem;
            // height: 28.861rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          // .card__number {
          //   position: absolute;
          //   bottom: 2.1rem;
          //   right: 0.688rem;
          //   width: 4.25rem;
          //   height: 4.25rem;
          //   color: rgba(0, 0, 0, 1);
          //   border-radius: 50%;
          //   background-color: $yellowColor;
          //   font-size: 2.5rem;
          //   font-weight: 700;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          // }
        }

        .card__caption {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.875rem;
          color: #838484;
          text-align: center;
        }
      }
    }
  } */
@media only screen and (max-width: 768px) {
  .popupgallery-container {
    width: 90vw;
    height: auto;
  }

  .image-container img {
    width: 90vw;
    height: 36vh;
    object-fit: cover;
  }

  .close-pop {
    top: 29vh;
    right: 0vw;
    width: 2.5rem;
    height: 2.5rem;
  }

  .arrow {
    width: 2rem;
    height: 2rem;
  }
}
