.gallery-page {
  width: 100%;
  background: url("../../images/ornamen-voyage.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.gallery-page img {
  width: 100%;
}

.contents-container {
  width: 68.75%;
  margin: 0 auto;
  padding: 0 0 2rem 0;
}

.contents-title {
  margin-top: 2rem;
  color: var(--color-yellow);
  text-align: center;
  font-family: var(--font-rotis);
}

.contents-title h3 {
  font-size: 2.375rem;
  font-weight: 400;
  line-height: 2.85rem;
}

.contents-title p {
  font-size: 0.938rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

/* .full-image {
  width: 100%;
}

.full-image img {
  width: 100%;
  height: 100%;
}

.grid-images {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto;
  grid-template-areas:
    "item1 item1 item2"
    "item1 item1 item3";
  gap: 0.7rem;
  overflow: hidden;
}

.item1 {
  grid-area: item1;
}

.item1 img {
  width: 100%;
  height: 99%;
}

.item2 {
  grid-area: item2;
}

.item3 {
  grid-area: item3;
}

.flex-images {
  display: flex;
  justify-content: space-between;
}

.img-item {
  width: 32.5%;
  height: 32.5%;
  overflow: hidden;
}

.img-item-v2 {
  width: 49.5%;
  height: 49.5%;
  overflow: hidden;
} */

/* UPDATE */
.room-wrapper {
  font-family: var(--font-rotis);
  color: var(--color-yellow);
  margin: 3rem 0 5rem 0;
}

.room-wrapper h4 {
  font-size: 1.85rem;
  text-align: center;
  margin-bottom: 1rem;
}

.card-images-room {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

/* .card-images-room .img-container {
  width: 23.5rem;
  height: 15rem;
  position: relative;
  overflow: hidden;
} */

.img-container img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-img-room {
  font-size: 1.3rem;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .gallery-page {
    margin-top: 5rem;
  }

  .card-images-room {
    flex-direction: column;
  }
}
