.navbar {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 7rem;
  overflow: hidden;
  background: rgba(1, 1, 1, 0.3);
  display: flex;
  align-items: center;
  z-index: 99;
  transition: all 0.3s ease;
}

.solid {
  background-color: rgba(1, 1, 1, 0.7);
  height: 5rem;
}

.navbar-container {
  width: 61.875rem;
  margin: 0 auto;
}

.navbar-logo {
  display: none;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: rgb(255, 255, 255);
}

.navigation ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}
.navigation ul li {
  width: 9rem;
  /* height: 2.222rem; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.navigation ul li span {
  font-family: var(--font-rotis);
  font-size: 1rem;
  font-weight: 400;
}

.navigation ul li img {
  width: 7%;
}

.navigation ul li .logo {
  width: 100%;
}

.navigation ul li .enquire-btn {
  font-weight: 600;
  font-size: 1.1rem;
}

.navigation ul li .enquire-btn:hover {
  cursor: pointer;
}

.translate {
  border: 0.1rem solid white;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  font-family: var(--font-crimson);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 6.25rem; */
  position: absolute;
  right: 6.25rem;
}

.button-menu {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .navbar {
    height: 5rem;
  }

  .navdark {
    background-color: rgba(1, 1, 1, 0.7);
  }

  .navbar-logo {
    display: block;
    margin-left: 0.5rem;
  }

  .navbar-logo img {
    width: 4rem;
  }

  .navigation {
    display: none;
  }

  .translate {
    right: 3.45rem;
  }

  .button-menu {
    display: block;
    margin-right: 1rem;
  }

  .button-menu button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 1.249rem;
    height: 1.054rem; */
    background: none;
    border: none;
    cursor: pointer;
  }

  .button-menu button svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  .button-menu button svg path {
    /* fill: 0.15rem; */
    stroke-width: 0.08rem;
  }
  .button-menu button svg circle {
    /* fill: 0.15rem; */
    stroke-width: 0.05rem;
  }
}
