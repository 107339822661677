.wrapper-packages {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container-packages {
  animation: packages 1s ease;
  width: 60%;
  padding: 4rem;
  background: url("../../images/ornamen-voyage.png");
  background-color: white;
  color: var(--color-yellow);
  border-top: 1rem solid var(--color-yellow);
  border-bottom: 1rem solid var(--color-yellow);
}

.text-box {
  background-color: var(--color-yellow);
  color: white;
  border-radius: 0.3rem;
  width: 45%;
  padding: 0.5rem;
  line-height: 1.4rem;
  margin-bottom: 2.5rem;
}

.text-box p {
  text-transform: uppercase;
}

.text-outerbox {
  color: black;
}

.text-outerbox h5 {
  color: black;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.text-outerbox .days {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.days-box {
  font-size: 1.25rem;
  color: white;
  background-color: var(--color-yellow);
  width: 4rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.days-box-desc {
  font-size: 1.25rem;
  line-height: 2rem;
}

@keyframes packages {
  from {
    opacity: 0;
    transform: translateY(-100vh);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hiddenPackages {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .container-packages {
    width: 80%;
  }

  .container-packages {
    padding: 1rem;
  }

  .text-outerbox {
    height: 50vh;
    overflow-y: scroll;
  }

  .text-outerbox h5 {
    font-size: 1rem;
  }

  .days-box {
    font-size: 1rem;
    width: auto;
    white-space: nowrap;
    padding: 0.5rem;
  }

  .days-box-desc {
    font-size: 1rem;
    line-height: 1.4rem;
  }
}
