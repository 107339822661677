.facilities-page {
  width: 100%;
  background: url("../../images/ornamen-voyage.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.facilities-page img {
  width: 100%;
}

.contents-container {
  width: 68.75%;
  margin: 0 auto;
}

.contents-title {
  margin-top: 2rem;
  color: var(--color-yellow);
  text-align: center;
  font-family: var(--font-rotis);
}

.contents-title h3 {
  font-size: 2.375rem;
  font-weight: 400;
  line-height: 2.85rem;
  margin-bottom: 1rem;
}

.contents-title h4 {
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2rem;
}

.contents-title p {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.grid-images {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto;
  grid-template-areas:
    "item1 item1 item2"
    "item1 item1 item3";
  gap: 0.7rem;
  overflow: hidden;
}

.item1 {
  grid-area: item1;
}

.item1 img {
  width: 100%;
  height: 99%;
}

.item2 {
  grid-area: item2;
}

.item3 {
  grid-area: item3;
}

.flex-images {
  display: flex;
  justify-content: space-between;
}

.img-item {
  width: 32.5%;
  height: 32.5%;
  overflow: hidden;
}

.img-item-v2 {
  width: 49.5%;
  height: 49.5%;
  overflow: hidden;
}

.img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-title {
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  font-size: 1.2rem;
  margin: 1rem 0;
  text-align: center;
}

/* UPDATE */
.meeting-room-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2.5rem;
}

.content-meeting-room {
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  text-align: right;
}
.content-meeting-room h4 {
  font-size: 1.875rem;
}

.left {
  text-align: left;
}

.content-meeting-room p {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.images-meeting-room {
  display: flex;
  gap: 0.75rem;
}

.images-meeting-room img {
  width: 22rem;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .facilities-page {
    margin-top: 4.9rem;
  }

  .meeting-room-wrapper {
    flex-wrap: wrap;
  }

  .images-meeting-room {
    overflow-x: auto;
    padding: 1rem 0;
    gap: 1rem;
  }
}
