.tagline {
  width: 100%;
  height: 18.5rem;
  background: url("../../images/tageline.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagline-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.tagline-first {
  /* font-family: "La Belle Aurore"; */
  font-family: var(--font-trajan);
  font-size: 2.5rem;
  line-height: 2.375rem;
  font-weight: 400;
}

.tagline-second {
  font-family: var(--font-rotis);
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.375rem;
}

@media only screen and (max-width: 768px) {
  .tagline {
    height: 12rem;
  }

  .tagline-first {
    font-size: 1.5rem;
    line-height: 2.375rem;
  }
  .tagline-second {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}
