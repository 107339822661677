.detail__voyagepage {
  width: 100%;
}

.detail__voyagepage img {
  width: 100%;
}

.contents-container {
  width: 80%;
  margin: 2rem auto;
  padding: 0 0 2rem 0;
}

.voyage-title {
  color: var(--color-yellow);
  font-family: var(--font-rotis);
  text-align: center;
  margin-bottom: 2.813rem;
}

.voyage-title h3 {
  font-size: 2.375rem;
  font-weight: 400;
}
.voyage-title p {
  font-size: 1.063rem;
  font-weight: 400;
}

/* Table */

.table__wrapper {
  overflow-x: auto;
  margin-bottom: 2rem;
  padding: 2rem 0;
}

.table__wrapper table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: var(--font-rotis);
  font-size: 1.063rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--color-black);
}

.table__wrapper table thead {
  /* background-color: var(--color-yellow); */
  background-color: #224156;
  color: white;
}

.table__wrapper table thead tr td {
  border: 0.05rem solid black;
  padding: 0.5rem 0 0.5rem 0.5rem;
  text-align: center;
}

.table__wrapper table tbody tr.yellow {
  background-color: var(--color-yellow);
  color: white;
}
.table__wrapper table tbody tr td {
  border: 0.05rem solid black;
  padding: 0.5rem;
}

.table__wrapper table tbody tr td.date {
  background-color: #224156;
  color: white;
  text-align: center;
}

.additional__wrapper ul {
  display: list-item;
  padding-left: 1rem;
}
.additional__wrapper ul li {
  list-style-type: disc;
  text-align: left;
  font-family: var(--font-rotis);
  font-size: 1.063rem;
  line-height: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .voyagepage {
    margin-top: 5rem;
  }

  .contents-container {
    width: 90%;
  }

  .table__wrapper table {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
