ul li {
  /* color: var(--warna-putih); */
  font-size: 1rem;
  font-weight: 400;
}

/* li a.active {
    color: var(--warna-kuning);
    background: none;
  }
  
  ul li:hover {
    color: var(--warna-kuning);
    cursor: pointer;
  } */

@media only screen and (max-width: 768px) {
  ul li {
    text-align: center;
    margin-bottom: 1rem;
  }
}
