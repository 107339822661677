.preview {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease-in;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}

.video-preview {
  position: fixed;
  /* top: 0;
  left: 0; */
  min-width: 100%;
  min-height: 100%;
}

.preview .contents {
  width: 16.883rem;
  height: 15rem; /* 2 button */
  /* height: 13rem; 1 button */
  /* height: 25rem; */
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.contents .logo-preview {
  width: 15rem;
  height: 10rem;
}

.contents .logo-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contents .explore {
  border: 0.1rem solid var(--color-white);
  width: 14.444rem;
  height: 2rem;
  font-style: 1.667rem;
  font-family: var(--font-italiana);
  color: var(--color-white);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contents .explore:hover {
  cursor: pointer;
}

@keyframes fadeOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    transform: scale(0);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
