.intro {
  width: 100%;
  background-image: url("../../images/cover-baru.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  /* background-position: 52% 65%; */
  background-size: cover;
}

.tag {
  position: absolute;
  top: 7.5rem;
  left: 5rem;
}

.tag p {
  /* text-shadow: 2px 2px 5px #000000; */
  text-transform: capitalize;
  /* font-family: "La Belle Aurore"; */
  font-family: var(--font-trajan);
  color: #fdfdfd;
  letter-spacing: 0.15rem;
  font-size: 3rem;
  /* margin-bottom: -0.5rem; */
}

@media only screen and (max-width: 768px) {
  .intro {
    background-position: 30% 65%;
  }
  .tag {
    top: 6rem;
    left: 1rem;
  }

  .tag p {
    font-size: 2rem;
  }
}
